import Vue from "vue";
import store from '@/store';

import translationsJSON from "@/translations.json";

// regex для поиска русских слов [\u0430-\u044f]+
export const translateLang = function(key){
  const lang = store.state.layout.language;

  const translation = translationsJSON[key]?.[lang] || key;
  if (translation === key){
    console.log('No translation for key: '+ key);
  }
  return translation;
};

export const translatePlugin = {
  install(){
    Vue.prototype.$translate = function(key){
      const lang = store.state.layout.language;
      const translation = translationsJSON[key]?.[lang] || key;
      if (translation === key){
        console.log(('No translation for key: '+ key));
      }
      return translation;
    };
  },
};