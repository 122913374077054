<template>
  <header class="layout__header">
    <div class="header--burger">
      <VAvatar color="#0086E7" size="32">
        <img
          v-if="avatarThumb"
          alt=""
          :src="avatarThumb"
        >
        <span v-else class="text-white user-select-none">
          {{
            (name ? name.charAt(0) : "") + (lastname ? lastname.charAt(0) : "")
          }}
        </span>
      </VAvatar>
      <p class="h5 ml-16px text-black">
        {{
          (name ? name : "") + " " + (lastname ? lastname.charAt(0) + "." : "")
        }}
      </p>
      <!-- <img
        class="header--burger__settings"
        src="@/assets/svg/v2_burger_gear.svg"
        alt=""
        @click="handleSettingsClick"
      > -->
    </div>
  </header>
</template>

<script>
import headerMixin from "@/mixins/header.js";
// import { backNavigateQueue } from "@/helpers/app";
export default {
  name: "AppBurgerHeader",
  mixins: [headerMixin],
  data() {
    return {
      avatarThumb: this.$store.state.user.avatarThumb,
      name: this.$store.state.user.name,
      lastname: undefined, //this.$store.state.user.lastname,
    };
  },
};
</script>
<style lang="scss">
.header--burger {
  display: flex;
  align-items: center;
  .header--burger__settings {
    margin-left: auto;
    // align-self: flex-start;
  }
}
</style>
