import router from '@/router';
import { isWebViewBridgeAvailable } from '@orta/bridge';

router.beforeEach((to, from, next) => {
  const isNotAvailable = !isWebViewBridgeAvailable();

  if (isNotAvailable && !['AppUpdate']?.includes(to.name) && process.env.NODE_ENV !== "development") {
    router.push({ name: 'AppUpdate' });
  } else {
    next();
  }
});