const state = () => ({
  isInitialized: false,
  avatar: '',
  avatarThumb: '',
  name: 'Имя',
  lastname: 'Фамилия',
  ortaMeData: undefined,
  aituPhoneData: undefined,
});

const mutations = {

  'set_name'(state, payload){
    state.name = payload;
  },
  'set_lastname'(state, payload){
    state.lastname = payload;
  },
  'set_avatar'(state, payload){
    state.avatar = payload;
  },
  'set_avatar_thumb'(state, payload){
    state.avatarThumb = payload;
  },
  'set_is_initialized'(state, payload){
    state.isInitialized = payload;
  },
  // used for dev purposes
  'set_orta_me_data'(state, payload){
    state.ortaMeData = payload;
  },
  'set_orta_phone_data'(state, payload){
    state.aituPhoneData = payload;
  },
};

const actions = {
  set({ commit }, { avatar, avatarThumb, name, lastname, phone }){
    console.log('user name', name);
    commit('set_avatar', avatar);
    commit('set_avatar_thumb', avatarThumb);
    commit('set_name', name);
    commit('set_lastname', lastname);
    commit('set_is_initialized', true);
    commit('set_orta_phone_data', phone);
  },
  // used for dev purposes
  setOrtaMeData({ commit }, payload){
    commit('set_orta_me_data', payload);
  },
};

const getters = {

};

export const user = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};