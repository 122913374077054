<template>
  <div
    class="main-sub-menu"
    :class="{
      'main-sub-menu--rounded': rounded,
      'main-sub-menu--rounded-top': roundedTop,
    }"
  >
    <div
      v-for="(i, k) in items"
      :id="i.form_name"
      :key="k+'_'+i.id"
      v-ripple
      class="main-sub-menu__item"
      :title="i.title"
      @click="() => handleItemClick(i)"
    >
      <div
        v-if="i.icon"
        class="item__icon"
      >
        <img alt="" :src="i.icon">
      </div>
      <div class="item__content">
        <p
          class="item-title"
          :style="(i.disabled || disabled) && 'color: #DDE1E5;'"
        >
          {{ getTranslated('title', i) }}
        </p>
        <p
          v-if="getTranslated('subtitle', i)"
          class="caption text-gray-200"
          :style="(i.disabled || disabled) && 'color: #DDE1E5;'"
        >
          {{ getTranslated('subtitle', i) }}
        </p>
      </div>
      <div>
        <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg" v-bind:svg-inline="''" v-bind:role="'presentation'" v-bind:focusable="'false'" v-bind:tabindex="'-1'"><path opacity=".2" d="M1 11l5-5-5-5" stroke="#111114" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    rounded: {
      type: Boolean,
      default: true,
    },
    roundedTop: {
      type: Boolean,
      default: false,
    },
    itemsNavigateBackToParent: {
      type: Boolean,
      default: true,
    },
    items: {
      type: Array,
      default(){
        return [];
      },
    },
  },
  computed: {
    ...mapState('layout', ['language']),
  },
  methods: {
    handleItemClick(i){
      if (i.disabled || this.disabled) return;

      if (i.mini_app_link) {
        window.location.href = i.mini_app_link;
      } else {
        this.$router.replace(this.getRouteToGo(i));
      }

      this.$emit('afterNav');
    },
    getRouteToGo(item) {
      const checkChildren = item?.has_children;

      let routeName = {};

      if (checkChildren) {
        routeName =  {
          name: 'NavigationItemChild',
          params: {
            menu_id: item.id,
          },
        };
      } else {
        if (this.itemsNavigateBackToParent) {
          switch (item.form_name) {
            case 'erg_way_form':
              routeName = {
                name: 'Way',
              };
              break;

            case 'my_calendar_form':
              routeName = {
                name: 'NavigationItemCalendarFormPage',
                params: {
                  menu_id: item.id,
                },
              };
              break;

            case 'market':
              routeName = {
                name: 'Marketplace',
              };
              break;

            default:
              routeName = {
                name: 'NavigationItemFormPage',
                params: {
                  menu_id: item.id,
                  form_name: item.form_name,
                },
              };
              break;
          }
        } else {
          switch (item.form_name) {
            case 'erg_way_form':
              routeName = {
                name: 'Way',
              };
              break;

            case 'my_calendar_form':
              routeName = {
                name: 'CalendarFormPage',
              };
              break;

            case 'market':
              routeName = {
                name: 'Marketplace',
              };
              break;

            default:
              routeName = {
                name: 'FormPage',
                params: {
                  form_name: item.form_name,
                },
              };
              break;
          }
        }
      }

      return routeName;
    },
    getTranslated(section, item){
      return item?.languages?.[this.language]?.[section] || item[section];
    },
  },
};
</script>

<style scoped lang="scss">
.item-title{
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
}
</style>