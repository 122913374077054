var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bottom-drawer",on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.$emit('close')}}},[_c('div',{staticClass:"bottom-drawer__body",class:{
      'bottom-drawer__body--gray': _vm.isBgGray
    },style:({
      height: _vm.height,
      maxHeight: _vm.maxHeight,
      minHeight: _vm.minHeight,
    })},[(_vm.notch)?_c('div',{staticClass:"bottom-drawer__body__notch"}):_vm._e(),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }