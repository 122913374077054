<template>
  <main class="layout__body">
    <template v-if="loading">
      <VSkeletonLoader
        v-for="n in 10"
        :key="n"
        class="w-full flex items-center gap-4 p-3 mt-2px"
        style="background-color: #FFFFFF;"
        type="sentences"
      />
    </template>
    <template v-else>
      <SubMenu
        :disabled="reloading"
        :items="menu.burgerItems"
        :items-navigate-back-to-parent="false"
        :rounded="false"
        :rounded-top="true"
        @afterNav="$store.dispatch('layout/hideBurgerContent')"
      />
    </template>

    <AppBurgerBodyItem
      :disabled="reloading"
      :icon="require('@/assets/svg/burger_earth.svg')"
      :icon-rotate="reloading"
      :right-arrow="true"
      :text-right="$translate('burger_lang_' + language)"
      :title="$translate('burger_lang_title')"
      @click.native="showLangDrawer = true"
    />
    <AppBurgerBodyItem
      :disabled="reloading"
      :icon="require('@/assets/svg/burger_onboard.svg')"
      :icon-rotate="reloading"
      :title="$translate('burger_onboard')"
      @click.native="$store.dispatch('mainPage/setNotOnboarded')"
    />
    <AppBurgerBodyItem
      :disabled="reloading"
      :icon="require('@/assets/svg/nav-menu_refresh-orange.svg')"
      :icon-rotate="reloading"
      :title="$translate('burger_reload')"
      @click.native="handleReloadClick"
    />
    <AppBurgerBodyItem
      v-if="logOutVisible"
      :disabled="reloading"
      :icon="require('@/assets/svg/log-out.svg')"
      :icon-rotate="reloading"
      :title="$translate('burger_exit')"
      @click.native="logOut"
    />
    <LanguageBottomDrawer
      v-if="showLangDrawer"
      @close="showLangDrawer = false"
    />
  </main>
</template>

<script>
import { mapState } from "vuex";
import SubMenu from "@/components/Main/SubMenu.vue";
import AppBurgerBodyItem from "./partials/AppBurgerBodyItem.vue";
import LanguageBottomDrawer from "./partials/LanguageBottomDrawer.vue";
import { logOut } from "@orta/bridge";

export default {
  name: "AppBurgerBody",
  components: {
    AppBurgerBodyItem,
    LanguageBottomDrawer,
    SubMenu,
  },
  data() {
    return {
      reloading: false,
      showLangDrawer: false,
    };
  },

  computed: {
    ...mapState("layout", ["language"]),
    ...mapState("mainPage", ["menu", "loading"]),
    logOutVisible() {
      return (
        process.env.NODE_ENV !== "development" &&
        this.menu.burgerItems.length
      );
    },
  },

  mounted() {
    if (!this.menu.burgerItems.length) {
      this.$store.dispatch('mainPage/getBurgerMenu');
    }
  },
  methods: {
    handleBurgerItemClick(to) {
      if (this.reloading) return;
      this.$router.push(to);
      this.$store.dispatch("layout/hideBurgerContent");
    },

    async handleReloadClick() {
      if (this.reloading) return;
      this.reloading = true;

      location.reload();
    },
    async logOut() {
      await logOut();
    },
  },
};
</script>
<style lang="scss">
.lang-drawer-content {
  &__item {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 16px;
    gap: 10px;
    background: #fafafa;
    margin: 0 16px 2px;
    &:first-child {
      border-radius: 16px 16px 0px 0px;
    }
    &:last-child {
      border-radius: 0px 0px 16px 16px;
    }
    &__text {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 26px;
      letter-spacing: 0.02em;
      color: #101828;
    }
  }
}
</style>
