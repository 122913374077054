import baseApi from "@/helpers/baseApi";
import { setAuthToken } from "@/helpers/auth.js";
import { translateLang } from "@/plugins/i18n.js";

export async function authViaBridge(data) {
  try {
    delete baseApi.defaults.headers['Authorization'];

    let res = await baseApi.put('api/user/create/',
      data,
    );
    console.log(res);

    if (res?.status === 204) {
      return handle204(data);
    } else if (res?.data?.auth_token){
      setAuthToken(res.data.auth_token);
      console.log('new token', res.data.auth_token);
      return res.data.auth_token;
    } else {
      // TODO report event via sentry
      throw Error(translateLang('app-loader_error_description_no-put-token'));
    }
  } catch (err){
    console.log('Ree Response', err?.response);
    return addAuthError(err);
  }
}

async function handle204(data){
  console.log('api/user/create PUT error, trying POST');
  try {
    let res = await baseApi.post('api/user/create/', data);
    if (res.data.auth_token){
      setAuthToken(res.data.auth_token);

      return res.data.auth_token;
    } else {
      console.log('error');
      // TODO report event via sentry
      throw Error(translateLang('app-loader_error_description_no-post-token'));
    }
  } catch (err) {

    return addAuthError(err);
  }
}

async function addAuthError(error){
  let errorCode = error?.response?.status;

  const finalErrorText = errorCode
    ? errorCode + ': ' + (error?.response?.data?.detail || error)
    : error?.response?.data?.detail || error;

  throw Error(finalErrorText);
}